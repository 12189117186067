import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'style/style.scss';

import Loading from 'components/atoms/Loading';

import SupplierRegister from 'screens/AuthenticationEnvironment/SupplierRegister';

import { MissingPage } from 'screens/Pages/MissingPage';
import { PageTemplate } from 'layouts/PageTemplate';

import SubscriptionOverview from 'screens/AdminEnvironment/Subscriptions';

const AccessDeniedPage = lazy(() => import('screens/Pages/AccessDeniedPage').then((module) => ({ default: module.AccessDeniedPage })));
const PageNotFound = lazy(() => import('screens/Pages/PageNotFound').then((module) => ({ default: module.PageNotFound })));

const Users = lazy(() => import('screens/Pages/Users').then((module) => ({ default: module.Users })));
const PermissionsOnRoles = lazy(() =>
    import('screens/Pages/PermissionsOnRoles').then((module) => ({ default: module.PermissionsOnRoles }))
);

const EmployerDashboard = lazy(() => import('screens/EmployerEnvironment/EmployerDashboard'));
const Declarations = lazy(() => import('screens/AdminEnvironment/Declarations/index'));
const TransactionsOverview = lazy(() => import('screens/AdminEnvironment/TransactionsOverview/index'));
const TransactionsOverviewTwo = lazy(() => import('screens/AdminEnvironment/TransactionsOverviewTwo/index'));
const Transactions = lazy(() => import('screens/AdminEnvironment/Transactions/index'));

const AdminRegister = lazy(() => import('screens/AuthenticationEnvironment/AdminRegister'));
const EmployerEmployeeRegister = lazy(() => import('screens/AuthenticationEnvironment/EmployerEmployeeRegister'));
const ContentCreatorRegister = lazy(() => import('screens/AuthenticationEnvironment/ContentCreatorRegister'));
const EmployerRegister = lazy(() => import('screens/AuthenticationEnvironment/GymEmployerRegister/Employer'));
const Login = lazy(() => import('screens/AuthenticationEnvironment/Login'));
const Tfa = lazy(() => import('screens/AuthenticationEnvironment/Tfa'));
const LoginWithUsername = lazy(() => import('screens/AuthenticationEnvironment/LoginWithUsername'));
const PasswordForgotten = lazy(() => import('screens/AuthenticationEnvironment/PasswordForgotten'));
const ResetPassword = lazy(() => import('screens/AuthenticationEnvironment/ResetPassword'));
const DeleteAccount = lazy(() => import('screens/AuthenticationEnvironment/DeleteAccount'));
const ConfirmEmployee = lazy(() => import('screens/AuthenticationEnvironment/ConfirmEmployee'));
const ConfirmEmployer = lazy(() => import('screens/AuthenticationEnvironment/ConfirmEmployer'));
const UnsubscribePage = lazy(() => import('screens/AuthenticationEnvironment/UnsubscribePage'));
const SubscriptionInformation = lazy(() => import('screens/AdminEnvironment/SubscriptionInformation'));

const ManageAdmins = lazy(() => import('screens/AdminEnvironment/ManageAdmins'));

const ManageEmployerEmployees = lazy(() => import('screens/EmployerEnvironment/ManageEmployees'));

const EntreoSubscriptionOverview = lazy(() => import('screens/Pages/ProvidersSubscriptions/index'));

const ManageChallengeTemplates = lazy(() => import('screens/AdminEnvironment/ChallengeTemplate'));
const ManageProviders = lazy(() => import('screens/Pages/ManageProviders').then((module) => ({ default: module.ManageProviders })));

const OverviewFitnessProducts = lazy(() =>
    import('screens/SupplierEnvironment/FitnessProducts').then((module) => ({ default: module.OverviewFitnessProducts }))
);
const SuppliersOverview = lazy(() => import('screens/AdminEnvironment/Suppliers'));
const CommissionDeterminedOverview = lazy(() => import('screens/AdminEnvironment/CommissionDeterminedProductsOverview'));

const ManageContentCreators = lazy(() => import('screens/ContentEnvironment/ManageContentCreators'));
const BlogOverview = lazy(() => import('screens/ContentEnvironment/Blog/OverviewBlog'));
const RecipeOverview = lazy(() => import('screens/ContentEnvironment/Recipe/OverviewRecipe'));
const ManagePictureBankItems = lazy(() => import('screens/AdminEnvironment/PictureBank'));

const Store = lazy(() => import('screens/AdminEnvironment/Store'));

const EntreoProductTagOverview = lazy(() => import('screens/AdminEnvironment/EntreoTags'));
const EntreoProductOverview = lazy(() => import('screens/AdminEnvironment/EntreoProducts/index'));

const LoginLayout = lazy(() => import('layouts/Login'));

const AuthorizationSendInvitations = lazy(() =>
    import('screens/Pages/AuthorizationSendInvidations').then((module) => ({ default: module.AuthorizationSendInvitations }))
);
const EmployersEmployers = lazy(() =>
    import('screens/Pages/EmployersEmployers').then((module) => ({ default: module.EmployersEmployers }))
);
const ProvidersEmployers = lazy(() =>
    import('screens/Pages/ProvidersEmployers').then((module) => ({ default: module.ProvidersEmployers }))
);
const ProvidersEmployees = lazy(() =>
    import('screens/Pages/ProvidersEmployees').then((module) => ({ default: module.ProvidersEmployees }))
);
const ProvidersEmployersChallenges = lazy(() =>
    import('screens/Pages/ProvidersEmployersChallenges').then((module) => ({ default: module.ProvidersEmployersChallenges }))
);
const EmployersChallenges = lazy(() =>
    import('screens/Pages/EmployersChallenges').then((module) => ({ default: module.EmployersChallenges }))
);
const ProviderEmployeeRegister = lazy(() =>
    import('screens/AuthenticationEnvironment/ProviderEmployeeRegister').then((module) => ({ default: module.ProviderEmployeeRegister }))
);
const ProviderEmployerRegister = lazy(() =>
    import('screens/AuthenticationEnvironment/ProviderEmployerRegister').then((module) => ({ default: module.ProviderEmployerRegister }))
);
const ProviderOrders = lazy(() =>
    import('screens/Pages/ProvidersOrders').then((module) => ({ default: module.ProviderOrders }))
);
const EmployerSettings = lazy(() => import('screens/EmployerEnvironment/Settings'));

const IFitDashboard = lazy(() => import('screens/Pages/IFitDashboard'));

export const AppRoutes = () => (
    <Suspense fallback={<Loading />}>
        <Routes>
            <Route path="account" element={<LoginLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="login/:redirectUrl" element={<Login />} />
                <Route path="register/admin/:code" element={<AdminRegister />} />
                <Route path="register/provider/employee/:code" element={<ProviderEmployeeRegister />} />
                <Route path="register/employer/employee/:code" element={<EmployerEmployeeRegister />} />
                <Route path="register/contentCreator/:code" element={<ContentCreatorRegister />} />
                <Route path="register/provider/employer/:code" element={<ProviderEmployerRegister />} />
                <Route path="register/employer/:code" element={<EmployerRegister />} />
                <Route path="register/supplier/:code" element={<SupplierRegister />} />
                <Route path="register/employee/confirm/:code" element={<ConfirmEmployee />} />
                <Route path="register/employer/confirm/:code" element={<ConfirmEmployer />} />
                <Route path="unsubscribe/:code" element={<UnsubscribePage />} />
                {process.env.NODE_ENV === 'development' && <Route path="loginwithusername/:username" element={<LoginWithUsername />} />}
                <Route path="tfa" element={<Tfa />} />
                <Route path="tfa/:redirectUrl" element={<Tfa />} />
                <Route path="password-forgotten" element={<PasswordForgotten />} />
                <Route path="password-forgotten-confirm" element={<PasswordForgotten step={2} />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="delete-account" element={<DeleteAccount />} />
            </Route>
            <Route path="/" element={<PageTemplate />}>
                <Route path="/dashboard/entreo" element={<MissingPage />} />
                <Route path="/dashboard/employers" element={<EmployerDashboard />} />
                <Route path="/dashboard/providers" element={<MissingPage />} />

                <Route path="/authorization/users" element={<Users />} />
                <Route path="/authorization/permissions" element={<PermissionsOnRoles />} />
                <Route path="/authorization/invitations" element={<AuthorizationSendInvitations />} />
                <Route path="/authorization/admins" element={<ManageAdmins />} />

                <Route path="/finance/payments" element={<MissingPage />} />
                <Route path="/finance/declarations" element={<Declarations />} />
                <Route path="/finance/manage-employer-license-fee" element={<MissingPage />} />
                <Route path="/finance/manage-license-fee" element={<MissingPage />} />
                <Route path="/finance/invoices" element={<MissingPage />} />
                <Route path="/finance/transactionsOverview" element={<TransactionsOverview />} />
                <Route path="/finance/transactionsOverviewTwo" element={<TransactionsOverviewTwo />} />
                <Route path="/finance/transactions/:id" element={<Transactions />} />
                <Route path="/finance/subscribers" element={<SubscriptionInformation />} />

                <Route path="/employers/manage-users" element={<ManageEmployerEmployees />} />
                <Route path="/employers/users/:id/i-fit-dashboard" element={<IFitDashboard />} />
                <Route path="/employers/manage-challenges" element={<EmployersChallenges />} />
                <Route path="/employers/employers" element={<EmployersEmployers />} />
                <Route path="/employers/settings" element={<EmployerSettings />} />

                <Route path="/providers/manage-employees" element={<ProvidersEmployees />} />
                <Route path="/providers/manage-providers" element={<ManageProviders />} />
                <Route path="/providers/manage-challenges" element={<MissingPage />} />
                <Route path="/providers/manage-subscriptions" element={<EntreoSubscriptionOverview />} />
                <Route path="/providers/manage-employers" element={<ProvidersEmployers />} />
                <Route path="/providers/manage-employers-challenges" element={<ProvidersEmployersChallenges />} />
                <Route path="/providers/manage-fitness-subscriptions" element={<MissingPage />} />
                <Route path="/providers/manage-orders" element={<ProviderOrders />} />

                <Route path="/challenges/manage-challenge-templates" element={<ManageChallengeTemplates />} />
                <Route path="/challenges/approve-challenges" element={<MissingPage />} />

                <Route path="/products/create-and-manage-products" element={<OverviewFitnessProducts />} />
                <Route path="/products/manage-suppliers" element={<SuppliersOverview />} />
                <Route path="/products/overview-supplier-products" element={<CommissionDeterminedOverview />} />

                <Route path="/content/blog" element={<BlogOverview />} />
                <Route path="/content/recipes" element={<RecipeOverview />} />
                <Route path="/content/images" element={<ManagePictureBankItems />} />
                <Route path="/content/content-creators" element={<ManageContentCreators />} />

                <Route path="/store/products-and-services" element={<Store />} />

                <Route path="/configuration/tags-and-tag-groups" element={<EntreoProductTagOverview />} />
                <Route path="/configuration/categories-and-products" element={<EntreoProductOverview />} />
                <Route path="/configuration/subscription-tasks" element={<SubscriptionOverview />} />
                <Route path="/access-denied" element={<AccessDeniedPage />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    </Suspense>
);
