import React, { useCallback, useState } from 'react';
import useModal from '../modal';
import Modal from 'components/organisms/Modal';
import { FormGroup, FormikFormControl, FormikFormSelect } from 'components/atoms/form';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button } from 'components/atoms/button';
import { AddressType, CountryType } from 'hooks/employers';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

interface AddressValues {
    id?: string;
    addressType: AddressType;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    country: CountryType;
}

const useAddAddressModal = (submit: (addressValues: AddressValues, index?: number) => void): [(index?: number, addressValues?: AddressValues) => void, () => void, boolean] => {
    const { t } = useTranslation('common');
    const [index, setIndex] = useState<number>();
    const [addressValues, setAddressValues] = useState<AddressValues | undefined>();

    // Methods.
    const handleOnSubmit = (values: AddressValues, helpers: FormikHelpers<AddressValues>) => {
        submit(values, index);
        hide();
    };

    const validationSchema = yup.object().shape({
        street: yup.string()
            .required(t('common:validation.isRequired').toString()),
        houseNumber: yup.string()
            .required(t('common:validation.isRequired').toString()),
        city: yup.string()
            .required(t('common:validation.isRequired').toString()),
        postalCode: yup.string()
            .required(t('common:validation.isRequired').toString())
    });

    const initialValues: AddressValues = {
        id: addressValues?.id,
        street: addressValues?.street ?? '',
        houseNumber: addressValues?.houseNumber ?? '',
        city: addressValues?.city ?? '',
        addressType: addressValues?.addressType ?? AddressType.billing,
        postalCode: addressValues?.postalCode ?? '',
        country: CountryType.NL
    };

    const [show, hide, isShowing] = useModal(
        ({ in: inProp, onExited }) => (
            <Modal onHide={hide} inProp={inProp} onExited={onExited} title="Adres toevoegen">
                <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
                    <Form>
                        <FormGroup label="Type adres">
                            <FormikFormSelect
                                name="addressType"
                                options={[
                                    { title: 'Factuuradres', value: AddressType.billing },
                                    { title: 'Bezoekadres', value: AddressType.visiting }
                                ]}
                            />
                        </FormGroup>
                        <FormGroup label="Straat" required>
                            <FormikFormControl name="street" />
                        </FormGroup>
                        <FormGroup label="Huisnummer" required>
                            <FormikFormControl name="houseNumber" />
                        </FormGroup>
                        <FormGroup label="Postcode" required>
                            <FormikFormControl name="postalCode" />
                        </FormGroup>
                        <FormGroup label="Woonplaats" required>
                            <FormikFormControl name="city" />
                        </FormGroup>
                        <FormGroup label="Land">
                            <FormikFormSelect name="country" options={[{ title: 'NL', value: CountryType.NL }]} />
                        </FormGroup>
                        <Button type="submit">
                            Toevoegen
                        </Button>
                    </Form>
                </Formik>
            </Modal>
        ),
        [addressValues]
    );

    const showModal = useCallback((index?: number, addressValues?: AddressValues) => {
        setAddressValues(addressValues);
        setIndex(index);
        show();
    }, [show]);

    return [showModal, hide, isShowing];
};

export default useAddAddressModal;
